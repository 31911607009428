import logo from './logo.svg';
import './Head.css';
import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";


function Emarketing() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullname, setFullname] = useState("");
   const [email, setEmail] = useState("");
   const [phone, setPhone] = useState("");
   const [objet, setObjet] = useState("");
   const [entite, setEntite] = useState("");
   const [message, setMessage] = useState("");
   const [messageError, setMessageError] = useState("");
   const [succesmsg, setSuccesmsg] = useState("");

  //Validation Email
  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // /\S+@\S+\.\S+/
  function isValidEmail(email) {
    return mailformat.test(email);
  }

  function handleClick() {
    setLoading(true);

    if (!isValidEmail(email)) {
      setSuccesmsg("");
      setMessageError("Erreur ! Veuillez saisir une adresse E-mail valide ⚠️");
      // console.log(formData.email);
      setTimeout(() => {
        setLoading(false);
      }, 500);

      return;
    }

    if (
      fullname === "" ||
      email === "" ||
      phone === "" ||
      objet === "" ||
      message === ""
    ) {
      setSuccesmsg("");
      setMessageError("Erreur ! Tous les champs sont obligatoires ⚠️");
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }

    if (
      fullname !== "" &&
      email !== "" &&
      phone !== "" &&
      entite !== "" &&
      objet !== "" &&
      message !== "" &&
      isValidEmail(email)
    ) {
      // console.log(fullname, email, phone, entite, objet, message);

      const service_id = "service_70y_stic_congo";
      const template_id = process.env.React_App_Emailjs_templateId;
      const user_id = process.env.React_App_Emailjs_userId;

      const sendEmail = async (name, email, phone, object, message) => {
        try {
          const response = await emailjs.send(
            service_id,
            template_id,
            { name, email, phone, object, message },
            user_id
          );

          if (response.status === 200) {
            // console.log("Successfully sent message.");
            setSuccesmsg(
              " Votre message a été envoyé avec succès! Nous vous recontacterons dans un instant ✔️"
            );
            setTimeout(() => {
              window.location.reload(true);
            }, 5000);
          }
        } catch (error) {
          // console.error("Failed to send email. Error: ", error);
          setMessageError("Erreur de connexion, veuillez réessayer svp ⚠️ ");
        }
      };

      sendEmail(
        fullname + " " + "de" + " " + entite,
        email,
        phone,
        objet,
        message
      );

      setMessageError("");
      // Perform operation and then set loading to false
      setTimeout(() => {
        setSuccesmsg("Envoie en cours...");
        setFullname("");
        setEmail("");
        setPhone("");
        setObjet("");
        setEntite("");
        setMessage("");
        setLoading(false);
      }, 500);
    }
  }
  return (
    <>
      <Header />
      <div class="bg-gray-50">
        <header class="bg-indigo-600"></header>

        <main>
          <div>
            <div class="relative bg-indigo-600">
              <div
                aria-hidden="true"
                class="absolute bottom-0 hidden h-6 w-full bg-gray-50 lg:block"
              ></div>

              <div class="relative bg-gradient-to-r from-yellow-500 to-blue-600 h-screen text-white overflow-hidden">
                <div class="absolute inset-0">
                  <img
                    src=".././social_N.gif"
                    alt="Background Image"
                    class="object-cover object-center w-full h-full "
                  />
                  <div class="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <div class="relative z-10 flex -mt-32 flex-col justify-center items-center h-full text-center">
                  <div class="relative mx-auto max-w-2xl px-6 pt-16 text-center sm:pt-32 lg:max-w-7xl lg:px-8">
                    <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                      <span class="block lg:inline">
                        Tout pour votre présence web,
                      </span>
                      <span class="block lg:inline text-yellow-500 ml-2 animate-pulse hover:text-blue-500">
                        Particuliers | Start-Up | PMI-PME
                      </span>
                    </h1>
                    {/* <p class="mt-4 text-xl text-indigo-100">
                      A travers le Produit GoLive! , le Cabinet STIC en
                      partenariat avec I-MEDIA CONSULTING, cultivent et
                      réinventent la communication digitale en la proposant aux
                      Particuliers, Star- up, PMI-PME avec une vision innovante
                      et adaptée au monde d’aujourd’hui et de demain
                    </p> */}
                  </div>
                  <div className="spot flex  space-x-4 mt-4">
                    <div class="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
                      <span class="absolute top-10 my-auto z-0 h-20 w-20 rounded-full bg-red-500 transition-all duration-300 group-hover:scale-[10]">
                        SMS Marketing
                      </span>
                      <div class="relative z-10 mx-auto max-w-md">
                        <span class="grid h-20 w-20 place-items-center rounded-full bg-blue-500 transition-all duration-300 group-hover:bg-yellow-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-10 w-10 text-white transition-all"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                            />
                          </svg>
                        </span>
                        <div class="space-y-6 pt-5 text-base leading-7 text-gray-600 text-justify transition-all duration-300 group-hover:text-white/90">
                          <p>
                            Touchez plus de clients avec notre offre SMS
                            campagne. Des message de promotions et de
                            notification de nouveaux produits/Services
                          </p>
                        </div>
                        <div class="pt-5 text-base font-semibold leading-7">
                          <p>
                            <a
                              href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour%20STIC-CONGO,%20je%20suis%20int%C3%A9ress%C3%A9%20par%20votre%20offre%20SMS"
                              target="_blank"
                              class="text-sky-500 transition-all duration-300 group-hover:text-white"
                            >
                              Démarrez &rarr;
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
                      <span class="absolute top-10 z-0 h-20 w-20 rounded-full bg-red-500 transition-all duration-300 group-hover:scale-[10]">
                        {" "}
                        API WhatSapp
                      </span>
                      <div class="relative z-10 mx-auto max-w-md">
                        <span class="grid h-20 w-20 place-items-center rounded-full bg-blue-500 transition-all duration-300 group-hover:bg-yellow-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-10 w-10 text-white transition-all"
                            viewBox="0 0 256 258"
                          >
                            <defs>
                              <linearGradient
                                id="logosWhatsappIcon0"
                                x1="50%"
                                x2="50%"
                                y1="100%"
                                y2="0%"
                              >
                                <stop offset="0%" stop-color="#1FAF38" />
                                <stop offset="100%" stop-color="#60D669" />
                              </linearGradient>
                              <linearGradient
                                id="logosWhatsappIcon1"
                                x1="50%"
                                x2="50%"
                                y1="100%"
                                y2="0%"
                              >
                                <stop offset="0%" stop-color="#F9F9F9" />
                                <stop offset="100%" stop-color="#FFF" />
                              </linearGradient>
                            </defs>
                            <path
                              fill="url(#logosWhatsappIcon0)"
                              d="M5.463 127.456c-.006 21.677 5.658 42.843 16.428 61.499L4.433 252.697l65.232-17.104a122.994 122.994 0 0 0 58.8 14.97h.054c67.815 0 123.018-55.183 123.047-123.01c.013-32.867-12.775-63.773-36.009-87.025c-23.23-23.25-54.125-36.061-87.043-36.076c-67.823 0-123.022 55.18-123.05 123.004"
                            />
                            <path
                              fill="url(#logosWhatsappIcon1)"
                              d="M1.07 127.416c-.007 22.457 5.86 44.38 17.014 63.704L0 257.147l67.571-17.717c18.618 10.151 39.58 15.503 60.91 15.511h.055c70.248 0 127.434-57.168 127.464-127.423c.012-34.048-13.236-66.065-37.3-90.15C194.633 13.286 162.633.014 128.536 0C58.276 0 1.099 57.16 1.071 127.416m40.24 60.376l-2.523-4.005c-10.606-16.864-16.204-36.352-16.196-56.363C22.614 69.029 70.138 21.52 128.576 21.52c28.3.012 54.896 11.044 74.9 31.06c20.003 20.018 31.01 46.628 31.003 74.93c-.026 58.395-47.551 105.91-105.943 105.91h-.042c-19.013-.01-37.66-5.116-53.922-14.765l-3.87-2.295l-40.098 10.513z"
                            />
                            <path
                              fill="#FFF"
                              d="M96.678 74.148c-2.386-5.303-4.897-5.41-7.166-5.503c-1.858-.08-3.982-.074-6.104-.074c-2.124 0-5.575.799-8.492 3.984c-2.92 3.188-11.148 10.892-11.148 26.561c0 15.67 11.413 30.813 13.004 32.94c1.593 2.123 22.033 35.307 54.405 48.073c26.904 10.609 32.379 8.499 38.218 7.967c5.84-.53 18.844-7.702 21.497-15.139c2.655-7.436 2.655-13.81 1.859-15.142c-.796-1.327-2.92-2.124-6.105-3.716c-3.186-1.593-18.844-9.298-21.763-10.361c-2.92-1.062-5.043-1.592-7.167 1.597c-2.124 3.184-8.223 10.356-10.082 12.48c-1.857 2.129-3.716 2.394-6.9.801c-3.187-1.598-13.444-4.957-25.613-15.806c-9.468-8.442-15.86-18.867-17.718-22.056c-1.858-3.184-.199-4.91 1.398-6.497c1.431-1.427 3.186-3.719 4.78-5.578c1.588-1.86 2.118-3.187 3.18-5.311c1.063-2.126.531-3.986-.264-5.579c-.798-1.593-6.987-17.343-9.819-23.64"
                            />
                          </svg>
                        </span>
                        <div class="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all text-justify duration-300 group-hover:text-white/90">
                          <p>
                            Connectez vous à WhatSapp Business et profitez des
                            services de Facebook Meta. Implémentez WhatSapp
                            validation OTP, ChatBot et WhatsApp Call Center
                          </p>
                        </div>
                        <div class="pt-5 text-base font-semibold leading-7">
                          <p>
                            <a
                              href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour%20STIC-CONGO,%20je%20suis%20int%C3%A9ress%C3%A9%20par%20votre%20offre%20WhatSapp%20Service20%API"
                              class="text-sky-500 transition-all duration-300 group-hover:text-white"
                            >
                              Démarrez &rarr;
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10 ">
                      <span class="absolute top-10 z-0 h-20 w-20 rounded-full bg-red-500 transition-all duration-300 group-hover:scale-[10]">
                        Page Réseaux sociaux
                      </span>
                      <div class="relative z-10 mx-auto max-w-md">
                        <span class="grid h-20 w-20 place-items-center rounded-full bg-blue-500 transition-all duration-300 group-hover:bg-yellow-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-10 w-10 text-white transition-all"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M384 336a63.78 63.78 0 0 0-46.12 19.7l-148-83.27a63.85 63.85 0 0 0 0-32.86l148-83.27a63.8 63.8 0 1 0-15.73-27.87l-148 83.27a64 64 0 1 0 0 88.6l148 83.27A64 64 0 1 0 384 336"
                            />
                          </svg>
                        </span>
                        <div class="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all text-justify duration-300 group-hover:text-white/90">
                          <p>
                            Faites la promotion de vos produits/services sur les réseaux sociaux pour toucher une grande communauté de consommateurs connectés 24H/24
                          </p>
                        </div>
                        <div class="pt-5 text-base font-semibold leading-7">
                          <p>
                            <a
                              href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour%20STIC-CONGO,%20je%20suis%20int%C3%A9ress%C3%A9%20par%20votre%20offre%20Get20%Followers"
                              class="text-sky-500 transition-all duration-300 group-hover:text-white"
                            >
                              Démarrez &rarr;
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour%20STIC-CONGO,%20je%20suis%20int%C3%A9ress%C3%A9%20par%20votre%20offre%20E-marketing"
                    target="_blank"
                    class="bg-yellow-400 mt-4 text-gray-900 hover:bg-yellow-300 py-2 px-6 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
                  >
                    Demandez un devis ⇾
                  </a>
                </div>
              </div>

              <h2 class="sr-only">Plans</h2>
              <div class="relative py-16 bg-white">
                <div
                  aria-hidden="true"
                  class="absolute inset-0  h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
                >
                  <div class="blur-[106px] h-56 bg-gradient-to-br from-teal-500 to-purple-400 dark:from-blue-700"></div>
                  <div class="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
                </div>

                <div class="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
                  <div class="relative">
                    <div class="flex items-center justify-center -space-x-2">
                      <img
                        loading="lazy"
                        width="400"
                        height="400"
                        src=".././images_yes.jpeg"
                        alt="member photo"
                        class="h-8 w-8 rounded-full object-cover"
                      />
                      <img
                        loading="lazy"
                        width="200"
                        height="200"
                        src=".././Devant_Portable.png"
                        alt="member photo"
                        class="h-12 w-12 rounded-full object-cover"
                      />
                      <img
                        loading="lazy"
                        width="200"
                        height="200"
                        src=".././dev.jpg"
                        alt="member photo"
                        class="z-10 h-16 w-16 rounded-full object-cover"
                      />
                      <img
                        loading="lazy"
                        width="200"
                        height="200"
                        src=".././1.webp"
                        alt="member photo"
                        class="relative h-12 w-12 rounded-full object-cover"
                      />
                      <img
                        loading="lazy"
                        width="200"
                        height="200"
                        src="young-african.jpeg"
                        alt="member photo"
                        class="h-8 w-8 rounded-full object-cover"
                      />
                    </div>

                    <div class="mt-6 m-auto space-y-6 md:w-8/12 lg:w-7/12">
                      <h1 class="text-center text-4xl font-bold text-gray-800 dark:text-white md:text-5xl">
                        Créez une communauté autour de votre produit/service
                      </h1>
                      <p class="text-center text-xl text-gray-600 dark:text-gray-300">
                        Faites partie des millions de personnes dans le monde
                        qui utilisent Facebook, Twitter, Instagram ... pour
                        toucher plus de clients. modernes.
                      </p>
                      <div class="flex flex-wrap justify-center gap-6">
                        <a
                          href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour%20STIC-CONGO,%20je%20suis%20int%C3%A9ress%C3%A9%20par%20votre%20offre%20Get20%Follers"
                          target="_blank"
                          class="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-teal-500 before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                        >
                          <span class="relative text-base font-semibold text-white dark:text-dark">
                            Service commercial ⇾
                          </span>
                        </a>
                        <button
                          onClick={() => setShowModal(true)}
                          class="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:border before:border-transparent before:bg-teal-500/10 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 sm:w-max"
                        >
                          <span class="flex relative text-base font-semibold text-teal-500 dark:text-white">
                            Envoyer un mail
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-8 h-5 mt-1"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fill="currentColor"
                                d="M18 7.373V14.5a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 2 14.5V7.373l7.746 4.558a.5.5 0 0 0 .508 0zM15.5 4a2.5 2.5 0 0 1 2.485 2.223L10 10.92L2.015 6.223A2.5 2.5 0 0 1 4.5 4z"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overflow-hidden bg-white -mt-60 ">
                <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                  <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                    <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                      <h1 className="text-4xl mt-8 font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Nous changeons votre façon de vous connecter au monde.
                      </h1>
                      <p className="mt-6 text-lg leading-8 text-justify text-gray-600 sm:max-w-md lg:max-w-none">
                        Vous cherchez à augmenter votre présence en ligne ? Vous
                        voulez atteindre un public plus large et plus ciblé ?
                        STIC Congo est là pour vous aider ! Nous offrons une
                        gamme complète de services de communication digitale, y
                        compris le marketing des médias sociaux, le SEO, la
                        publicité en ligne, et plus encore. Avec notre expertise
                        et notre dévouement, nous pouvons vous aider à atteindre
                        vos objectifs de marketing digital.
                      </p>
                      <div className="mt-10 flex items-center gap-x-6">
                        <a
                          href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour%20STIC-CONGO,%20je%20suis%20int%C3%A9ress%C3%A9%20par%20votre%20offre%20E-marketing"
                          target="_blank"
                          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Demandez un devis →
                        </a>
                        {/* <a
                          href="#"
                          className="text-sm font-semibold leading-6 text-gray-900"
                        >
                          Live demo <span aria-hidden="true">→</span>
                        </a> */}
                      </div>
                    </div>
                    <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                      <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                        <div className="relative ">
                          <img
                            src=".././web.webp"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg "
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                      </div>
                      <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                        <div className="relative">
                          <img
                            src=".././web1.webp"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative">
                          <img
                            src=".././boy.webp"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                      </div>
                      <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                        <div className="relative">
                          <img
                            src=".././cam.jpg"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                        <div className="relative">
                          <img
                            src="d_d.webp"
                            alt=""
                            className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                          />
                          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto w-full">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl text-gray-700 mx-auto ">
                    {/* TEST DE MESSAGE */}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                <div class="mt-6 overflow-hidden bg-white rounded-xl">
                  <div class="px-6 py-12 sm:p-12">
                    <h3 class="text-3xl font-semibold text-center text-gray-900">
                      Envoyer un message
                    </h3>
                    <p className="text-2xl text-semibold p-4 text-center mx-auto text-red-400">
                      {" "}
                      {messageError}{" "}
                    </p>
                    <p class="text-center p-2 text-2xl text-green-500">
                      {succesmsg}{" "}
                    </p>
                    <div className="mt-14">
                      <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
                        <div>
                          <label
                            for=""
                            class="text-base font-medium text-gray-900"
                          >
                            {" "}
                            Nom et Prénom(s){" "}
                          </label>
                          <div class="mt-2.5 relative">
                            <input
                              type="text"
                              name="fullname"
                              value={fullname}
                              onChange={(e) => {
                                setFullname(e.target.value);
                              }}
                              id="fullnme"
                              placeholder="Christian LUALUA"
                              class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            for=""
                            class="text-base font-medium text-gray-900"
                          >
                            {" "}
                            Votre E-mail{" "}
                          </label>
                          <div class="mt-2.5 relative">
                            <input
                              type="email"
                              name="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              id="email"
                              placeholder="exemple@exemple.com"
                              class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            for=""
                            class="text-base font-medium text-gray-900"
                          >
                            {" "}
                            Numéro whatsApp{" "}
                          </label>
                          <div class="mt-2.5 relative">
                            <input
                              type="text"
                              name="phone"
                              value={phone}
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                              id="phone"
                              placeholder="+24201020304"
                              class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            for=""
                            class="text-base font-medium text-gray-900"
                          >
                            {" "}
                            Nom de votre Société{" "}
                          </label>
                          <div class="mt-2.5 relative">
                            <input
                              type="text"
                              name="entite"
                              value={entite}
                              onChange={(e) => {
                                setEntite(e.target.value);
                              }}
                              id="entite"
                              placeholder="Congo Oil"
                              class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                            />
                          </div>
                        </div>
                        <div class="sm:col-span-2">
                          <label
                            for=""
                            class="text-base font-medium text-gray-900"
                          >
                            {" "}
                            Objet{" "}
                          </label>
                          <div class="mt-2.5 relative">
                            <input
                              name="objet"
                              id="objet"
                              value={objet}
                              onChange={(e) => {
                                setObjet(e.target.value);
                              }}
                              placeholder="Prise de contact..."
                              class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus:outline-none focus:border-blue-600 caret-blue-600"
                              rows="4"
                            ></input>
                          </div>
                        </div>

                        <div class="sm:col-span-2">
                          <label
                            for=""
                            class="text-base font-medium text-gray-900"
                          >
                            {" "}
                            Message{" "}
                          </label>
                          <div class="mt-2.5 relative">
                            <textarea
                              name="message"
                              id="message"
                              value={message}
                              onChange={(e) => {
                                setMessage(e.target.value);
                              }}
                              placeholder="Bonjour..."
                              class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus:outline-none focus:border-blue-600 caret-blue-600"
                              rows="4"
                            ></textarea>
                          </div>
                        </div>

                        <div class="sm:col-span-2">
                          <button
                            type="submit"
                            onClick={handleClick}
                            enable={loading}
                            class="inline-flex items-center justify-center w-full px-4 py-4 mt-2 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md focus:outline-none hover:bg-blue-700 focus:bg-blue-700"
                          >
                            {loading ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 animate-spin mx-auto"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                              </svg>
                            ) : (
                              "Valider"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Emarketing;
