import React, { useState, useEffect } from "react";

const LogoCarousel = ({ logos }) => {
  const [translateValue, setTranslateValue] = useState(0);
  const slideWidth = 200; // Change this according to your logo width + margin
  const autoSlideDelay = 2000; // Adjust the delay between slides (in milliseconds)

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateValue((prevValue) => prevValue - slideWidth);
    }, autoSlideDelay);

    return () => clearInterval(interval);
  }, []);

  const handleTransitionEnd = () => {
    // Reset carousel position if it reaches the end
    if (translateValue <= -(slideWidth * (logos.length - 1))) {
      setTranslateValue(0);
    }
  };

  const carouselStyle = {
    transform: `translateX(${translateValue}px)`,
    transition: "transform 0.5s ease-in-out",
  };

  return (
    <div className="overflow-hidden">
      <div
        className="flex space-x-4"
        style={carouselStyle}
        onTransitionEnd={handleTransitionEnd}
      >
        {logos.map((logo, index) => (
          <img
            key={index}
            src={logo}
            alt={`Logo ${index + 1}`}
            className="w-32 h-32 object-contain"
          />
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;
