import logo from './logo.svg';
import './Head.css';
import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";


function Golive() {

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Header />
      <div class="bg-gray-50">
        <header class="bg-indigo-600"></header>

        <main>
          <div>
            <div class="relative bg-indigo-600">
              <div
                aria-hidden="true"
                class="absolute bottom-0 hidden h-6 w-full bg-gray-50 lg:block"
              ></div>

              <div class="relative bg-gradient-to-r from-yellow-500 to-blue-600 h-screen text-white overflow-hidden">
                <div class="absolute inset-0">
                  <img
                    src=".././laptop.jpeg"
                    alt="Background Image"
                    class="object-cover object-center w-full h-full"
                  />
                  <div class="absolute inset-0 bg-black opacity-50"></div>
                </div>

                <div class="relative z-10 flex -mt-32 flex-col justify-center items-center h-full text-center">
                  <div class="relative mx-auto max-w-2xl px-6 pt-16 text-center sm:pt-32 lg:max-w-7xl lg:px-8">
                    <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                      <span class="block lg:inline">
                        OFFRE COMPLETE DE CREATION DE SITE WEB,
                      </span>
                      <span class="block lg:inline text-yellow-500 ml-2 animate-pulse hover:text-blue-500">
                        Particuliers | Start-Up | PMI-PME
                      </span>
                    </h1>
                    <p class="mt-4 text-xl text-indigo-100">
                      A travers le Produit GoLive! , le Cabinet STIC en
                      partenariat avec I-MEDIA CONSULTING, cultivent et
                      réinventent la communication digitale en la proposant aux
                      Particuliers, Star- up, PMI-PME avec une vision innovante
                      et adaptée au monde d’aujourd’hui et de demain
                    </p>
                  </div>
                  <a
                    href=".././Golive.pdf"
                    target="_blank"
                    class="bg-yellow-400 mt-4 text-gray-900 hover:bg-yellow-300 py-2 px-6 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
                  >
                    Prospectus Golive ⇾
                  </a>
                </div>
              </div>

              <h2 class="sr-only">Plans</h2>

              <div class="relative mt-12 flex justify-center sm:mt-16">
                <div id="tarifs" class="flex rounded-lg bg-indigo-700 p-0.5">
                  <button
                    type="button"
                    class="relative whitespace-nowrap rounded-md border-indigo-700 bg-white py-2 px-6 text-sm font-medium text-indigo-700 shadow-sm hover:bg-indigo-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700"
                  >
                    Nos tarifs
                  </button>
                  <a
                    href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour STIC Congo. J'aimerais avoir un devis pour un site..."
                    target="_blank"
                    class="relative  ml-4 whitespace-nowrap rounded-md border border-transparent py-2 px-6 text-sm font-medium text-indigo-200 hover:bg-indigo-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700"
                  >
                    Demander un devis ⇾
                  </a>
                </div>
              </div>

              <div class="relative mx-auto mt-8 max-w-2xl px-6 pb-8 sm:mt-12 lg:max-w-7xl lg:px-8 lg:pb-0">
                <div
                  aria-hidden="true"
                  class="absolute inset-0 top-4 bottom-6 left-8 right-8 hidden rounded-tl-lg rounded-tr-lg bg-indigo-700 lg:block"
                ></div>

                <div class="relative space-y-6 lg:grid lg:grid-cols-3 lg:space-y-0">
                  <div class="bg-indigo-700 lg:bg-transparent pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                    <div>
                      <h3 class="text-white text-base font-semibold">
                        Particulier
                      </h3>
                      <div class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                        <div class="mt-3 flex items-center">
                          <p class="text-white text-4xl font-bold tracking-tight">
                            400.000F
                          </p>
                          <div class="ml-4">
                            <p class="text-white text-sm">CFA</p>
                            <p class="text-indigo-200 text-sm">Site vitrine</p>
                          </div>
                        </div>
                        <a
                          href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour STIC Congo. J'aimerais souscrire à votre plan pour Particulier"
                          target="_blank"
                          class="bg-white text-indigo-600 hover:bg-indigo-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                        >
                          Commander
                        </a>
                      </div>
                    </div>
                    <h4 class="sr-only">Features</h4>
                    <ul
                      role="list"
                      class="border-indigo-500 divide-indigo-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0"
                    >
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Création du site
                        </span>
                      </li>

                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Nom de domaine (.cg/com/net/org/africa)
                        </span>
                      </li>

                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Hébergement privé
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          10 adresses mails
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Espace disque : Illimité
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          01 Certificat SSL (Alpha SSL)
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Présence numérique
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Accompagnement création du contenu
                        </span>
                      </li>
                      <p className="mb-8 text-yellow-400 text-justify">
                        {" "}
                        NB: Renouvellement nom de domaine/Hébergement/ SSL/
                        Compte mails Exchange + Maintenance : 100.000F/an
                      </p>
                    </ul>
                  </div>

                  <div class="bg-white ring-2 ring-indigo-700 shadow-md pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                    <div>
                      <h3 class="text-indigo-600 text-base font-semibold">
                        Start-up
                      </h3>
                      <div class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                        <div class="mt-3 flex items-center">
                          <p class="text-indigo-600 text-4xl font-bold tracking-tight">
                            500.000
                          </p>
                          <div class="ml-4">
                            <p class="text-gray-700 text-sm">FCFA</p>
                            <p class="text-gray-500 text-sm">
                              Blogs & E-commerce
                            </p>
                          </div>
                        </div>
                        <a
                          href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour STIC Congo. J'aimerais souscrire à votre plan pour Start-up"
                          target="_blank"
                          class="bg-indigo-600 text-white hover:bg-indigo-700 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                        >
                          Commander maintenant
                        </a>
                      </div>
                    </div>
                    <h4 class="sr-only">Features</h4>
                    <ul
                      role="list"
                      class="border-indigo-500 divide-indigo-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0"
                    >
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-blue-500 ml-4 text-sm font-medium">
                          Création du site
                        </span>
                      </li>

                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-blue-500 ml-4 text-sm font-medium">
                          Nom de domaine (.cg/com/net/org/africa)
                        </span>
                      </li>

                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-blue-500 ml-4 text-sm font-medium">
                          Hébergement privé
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-blue-500 ml-4 text-sm font-medium">
                          20 Comptes Messagerie
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-blue-500 ml-4 text-sm font-medium">
                          Espace disque : Illimité
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-blue-500 ml-4 text-sm font-medium">
                          01 Certificat SSL (Alpha SSL)
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-blue-500 ml-4 text-sm font-medium">
                          Présence numérique
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-blue-500 ml-4 text-sm font-medium">
                          Accompagnement création du contenu
                        </span>
                      </li>
                      <p className="mb-8 text-gray-800 text-justify">
                        {" "}
                        NB: Renouvellement nom de domaine/Hébergement/ SSL/
                        Compte mails Exchange + Maintenance : 115.000F/an
                      </p>
                    </ul>
                  </div>

                  <div class="bg-indigo-700 lg:bg-transparent pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12">
                    <div>
                      <h3 class="text-white text-base font-semibold">
                        PMI-PME
                      </h3>
                      <div class="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                        <div class="mt-3 flex items-center">
                          <p class="text-white text-4xl font-bold tracking-tight">
                            675.000
                          </p>
                          <div class="ml-4">
                            <p class="text-white text-sm">FCFA</p>
                            <p class="text-indigo-200 text-sm">
                              Application web
                            </p>
                          </div>
                        </div>
                        <a
                          href="#https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour STIC Congo. J'aimerais souscrire à votre plan pour PMI-PME"
                          target="blank"
                          class="bg-white text-indigo-600 hover:bg-indigo-50 mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                        >
                          Lancer votre serveur maintenant
                        </a>
                      </div>
                    </div>
                    <h4 class="sr-only">Features</h4>
                    <ul
                      role="list"
                      class="border-indigo-500 divide-indigo-500 divide-opacity-75 mt-7 border-t divide-y lg:border-t-0"
                    >
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Création du site
                        </span>
                      </li>

                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Nom de domaine (.cg/com/net/org/africa)
                        </span>
                      </li>

                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Hébergement privé
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          +50 adresses mails
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Espace disque : Illimité
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          01 Certificat SSL (Alpha SSL)
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Présence numérique (création des comptes réseaux
                          sociaux)
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Accompagnement à la rédaction et la fabrication du
                          contenu
                        </span>
                      </li>
                      <li class="flex items-center py-3">
                        <svg
                          class="text-indigo-200 w-5 h-5 flex-shrink-0"
                          x-description="Heroicon name: mini/check"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="text-white ml-4 text-sm font-medium">
                          Formation à la mise à jour du site
                        </span>
                      </li>
                      <p className="mb-8 text-yellow-400 text-justify">
                        {" "}
                        NB: Renouvellement nom de domaine/Hébergement/ SSL/
                        Compte mails Exchange + Maintenance : 180.000F/an
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mx-auto max-w-2xl py-12 -mt-24 px-6 lg:max-w-7xl lg:py-32 lg:px-8">
            <div class="relative flex flex-col items-center mx-auto lg:flex-row-reverse lg:max-w-5xl lg:mt-12 xl:max-w-6xl">
              <div class="w-full h-64 lg:w-1/2 lg:h-auto">
                <img
                  class="h-full w-full object-cover  hover:-translate-y-1 hover:scale-110 rounded-lg"
                  src=".././11.jpg"
                  alt="SIC CONGO"
                />
              </div>

              <div class="max-w-lg bg-gradient-to-r from-yellow-500 to-blue-600  md:max-w-2xl md:z-10 md:shadow-lg md:absolute md:top-0 md:mt-48 lg:w-3/5 lg:left-0 lg:mt-20 lg:ml-20 xl:mt-24 xl:ml-12">
                <div class="flex flex-col p-12 md:px-16">
                  <h2 class="text-2xl font-medium uppercase text-white hover:text-yellow-500 lg:text-2xl">
                    Votre site web au Design unique, Moderne & Professionnel
                  </h2>

                  <p class="-mt-16 text-justify">
                    <div class="flex flex-col mb-8 md:mb-auto gap-3.5 flex-1 p-4 mt-16">
                      <h2 class="flex gap-3 items-center m-auto text-lg font-bold md:flex-col md:gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="m4.538 18l3.847-6l-3.847-6h9.539q.538 0 1.014.244t.79.683l3.506 4.963q-.331-.077-.715-.12q-.384-.045-.707-.04q-2.565.012-4.38 1.763q-1.816 1.751-1.95 4.282q0 .058.003.113q.002.054.014.112H4.538ZM18 22.115q-1.671 0-2.836-1.164T14 18.115q0-1.67 1.164-2.835T18 14.115q1.671 0 2.836 1.165T22 18.115q0 1.672-1.164 2.836Q19.67 22.115 18 22.115Zm-.629-2.288l2.84-2.796l-.626-.627l-2.214 2.183l-.956-.975l-.627.632l1.583 1.583Z"
                          />
                        </svg>
                        TOUT FOURNI
                      </h2>
                      <ul class="flex flex-col gap-3.5 text-justify w-full sm:max-w-md m-auto">
                        <li class="w-full bg-gray-100 dark:bg-white/5 p-3 rounded-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                          >
                            <g fill="none">
                              <g clip-path="url(#healthicons1Negative0)">
                                <path
                                  fill="currentColor"
                                  fill-rule="evenodd"
                                  d="M48 0H0v48h48V0ZM29 12a2 2 0 0 0-3.414-1.414l-5 5a2 2 0 1 0 2.828 2.828L25 16.828V36a2 2 0 0 0 4 0V12Z"
                                  clip-rule="evenodd"
                                />
                              </g>
                              <defs>
                                <clipPath id="healthicons1Negative0">
                                  <path d="M0 0h48v48H0z" />
                                </clipPath>
                              </defs>
                            </g>
                          </svg>{" "}
                          Conception de votre site web Un site web clé en main
                          au design épuré, grande technicité de construction et
                          doté d'un fort potentiel d'évolution et d’adaptabilité
                          à tous les devices
                        </li>
                        <li class="w-full bg-gray-100 text-justify dark:bg-white/5 p-3 rounded-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                          >
                            <g fill="none">
                              <g clip-path="url(#healthicons2Negative0)">
                                <path
                                  fill="currentColor"
                                  fill-rule="evenodd"
                                  d="M48 0H0v48h48V0ZM30 18a4 4 0 0 0-4-4h-4a4.002 4.002 0 0 0-3.773 2.666a2 2 0 0 1-3.771-1.332A8.003 8.003 0 0 1 22 10h4a8 8 0 0 1 5.364 13.935L20.948 34H32a2 2 0 1 1 0 4H16a2 2 0 0 1-1.39-3.438l14-13.528l.056-.052A3.985 3.985 0 0 0 30 18Z"
                                  clip-rule="evenodd"
                                />
                              </g>
                              <defs>
                                <clipPath id="healthicons2Negative0">
                                  <path d="M0 0h48v48H0z" />
                                </clipPath>
                              </defs>
                            </g>
                          </svg>{" "}
                          Redaction des contenus du site Nos équipes s’occupent
                          de la rédaction ou de l’optimisation de contenu de
                          votre site web
                        </li>
                        <li class="w-full bg-gray-100 text-justify dark:bg-white/5 p-3 rounded-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                          >
                            <g fill="none">
                              <g clip-path="url(#healthicons3Negative0)">
                                <path
                                  fill="currentColor"
                                  fill-rule="evenodd"
                                  d="M48 0H0v48h48V0ZM30 18c0-2.169-1.808-4-4.13-4h-4.088c-1.823 0-3.344 1.137-3.9 2.68a2 2 0 0 1-3.763-1.36c1.126-3.118 4.147-5.32 7.663-5.32h4.087C30.32 10 34 13.541 34 18a7.91 7.91 0 0 1-2.753 6A7.91 7.91 0 0 1 34 30c0 4.459-3.681 8-8.13 8h-4.088c-3.516 0-6.537-2.202-7.663-5.32a2 2 0 0 1 3.762-1.36c.557 1.543 2.078 2.68 3.901 2.68h4.087C28.192 34 30 32.169 30 30s-1.808-4-4.13-4h-4.088a2 2 0 1 1 0-4h4.087C28.192 22 30 20.169 30 18Z"
                                  clip-rule="evenodd"
                                />
                              </g>
                              <defs>
                                <clipPath id="healthicons3Negative0">
                                  <path d="M0 0h48v48H0z" />
                                </clipPath>
                              </defs>
                            </g>
                          </svg>{" "}
                          Acconpagnement à la promotion du site Après les tests
                          d’affichages et de fonctionnalités de votre site, nous
                          le mettons en ligne et procédons à sa promotion sur
                          les moteurs de recherche et les réseaux sociaux .
                        </li>
                      </ul>
                    </div>
                  </p>

                  {/* <div class="mt-8">
                    <a
                      href="#"
                      class="inline-block w-full text-center text-lg font-medium text-gray-100 bg-green-600 border-solid border-2 border-gray-600 py-4 px-10 hover:bg-green-800 hover:shadow-md md:w-48"
                    >
                      Read More
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <section aria-labelledby="faq-heading" class="bg-gray-900 mt-32">
            <div class="mx-auto max-w-2xl py-16 px-6 sm:py-24 lg:max-w-7xl lg:px-8">
              <div class="max-w-2xl lg:mx-auto lg:text-center">
                <h2
                  id="faq-heading"
                  class="text-3xl font-bold tracking-tight text-white sm:text-4xl"
                >
                  Questions fréquemment posées
                </h2>
                <p class="mt-4 text-gray-400">
                  Ces questions couvrent les aspects essentiels que les clients
                  potentiels pourraient vouloir savoir avant de s’engager dans
                  un service de création de sites Web.
                </p>
              </div>
              <div class="mt-20">
                <dl class="space-y-10 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10 lg:space-y-0">
                  <div>
                    <dt class="font-semibold text-white">
                      Qu’est-ce que votre service de création de sites Web offre
                      ?
                    </dt>
                    <dd class="mt-3 text-gray-400 text-justify">
                      Lorsque nous concevons votre projet avec GoLive , notre
                      but est de réaliser un site qui ait une architecture
                      logique, une ergonomie et une navigation intuitive ainsi
                      qu'un design original et créatif.
                    </dd>
                  </div>

                  <div>
                    <dt class="font-semibold text-white">
                      Combien coûte la création d’un site Web avec votre service
                      ?
                    </dt>
                    <dd class="mt-3 text-gray-400 text-justify">
                      Nos tarif respectent le budget du client. Nous offrons
                      trois (03) plan tarifaires à la portée de tous.
                    </dd>
                  </div>

                  <div>
                    <dt class="font-semibold text-white">
                      Combien de temps faut-il pour créer un site Web ?
                    </dt>
                    <dd class="mt-3 text-gray-400 text-justify">
                      Pour atteindre ce résultat nous respectons scrupuleusement
                      notre méthodologie à succès avec un deadline de 05 jours
                      après la la signature du contrat.
                    </dd>
                  </div>

                  <div>
                    <dt class="font-semibold text-white">
                      Puis-je personnaliser mon site Web selon mes préférences ?
                    </dt>
                    <dd class="mt-3 text-gray-400 text-justify">
                      Nous nous engageons à vous offrir une prestation
                      professionnelle de qualité pour la conception et le
                      développement de votre site internet dans les règles de
                      l’art du web par une équipe professionnelle d’experts dans
                      les nouvelles technologies d’informations et de la
                      communication. Nous prenons en compte vos goûts et
                      exigences pour donner vie à vos envies.
                    </dd>
                  </div>

                  <div>
                    <dt class="font-semibold text-white">
                      Fournissez-vous un service de maintenance après la
                      création du site Web ?
                    </dt>
                    <dd class="mt-3 text-gray-400 text-justify">
                      Notre plan tarifaire vous donne un point de vue qui
                      nécessite de comparer les caractéristiques de chaque plan,
                      puis de choisir le plan qui convient à votre projet.
                      Toutefois nos plans prennent en compte la maintenance de
                      votre site web.
                    </dd>
                  </div>

                  <div>
                    <dt class="font-semibold text-white">
                      Mon site sera-t-il optimisé pour le référencement (SEO) ?
                    </dt>
                    <dd class="mt-3 text-gray-400 text-justify">
                      GoLive , la solution pour site web sur mesure. Nous
                      promouvons et référençons vote site web dans son secteur
                      d’activité, sur les moteurs de recherche, sur les réseaux
                      sociaux…
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default Golive;
