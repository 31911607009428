import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Accueil from './Accueil';
import reportWebVitals from './reportWebVitals';
import Golive from './Golive';
import ConditionsGeneralesVentes from './ConditionsGeneralesVentes';
import Emarketing from './Emarketing';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <div>
        <section>
          <Routes>
            {" "}
            <Route path="/" element={<Accueil />} />
            <Route path="/Golive" element={<Golive />} />
            <Route
              path="/ConditionsGeneralesVentes"
              element={<ConditionsGeneralesVentes />}
            />
            <Route
              path="/Emarketing"
              element={<Emarketing />}
            />
          </Routes>
        </section>
      </div>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
