import logo from "./logo.svg";
import LogoCarousel from "./Sliders";
import "./Head.css";
import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

function Accueil() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [objet, setObjet] = useState("");
  const [entite, setEntite] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [succesmsg, setSuccesmsg] = useState("");

const logos = [
  ".././Ibs_0.jpeg",
  ".././Dangote.png",
  ".././calbank.png",
  ".././Ibs_0.jpeg",
  ".././i-media.png",
  ".././Midwestern.png",
  ".././Origid.png",
  ".././Axxela.png",
  ".././AEDC.png",
  ".././aws.png",
  ".././Sahcol1.png",
  ".././amazone.png",
  ".././AFRICAN'S-BUILDING-Consulting-02-.jpg",
  ".././logo_VINSYS.png",
];

  //Validation Email
  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // /\S+@\S+\.\S+/
  function isValidEmail(email) {
    return mailformat.test(email);
  }

  function handleClick() {
    setLoading(true);

    if (!isValidEmail(email)) {
      setSuccesmsg("");
      setMessageError("Erreur ! Veuillez saisir une adresse E-mail valide ⚠️");
      // console.log(formData.email);
      setTimeout(() => {
        setLoading(false);
      }, 500);

      return;
    }

    if (
      fullname === "" ||
      email === "" ||
      phone === "" ||
      objet === "" ||
      message === ""
    ) {
      setSuccesmsg("");
      setMessageError("Erreur ! Tous les champs sont obligatoires ⚠️");
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }

    if (
      fullname !== "" &&
      email !== "" &&
      phone !== "" &&
      entite !== "" &&
      objet !== "" &&
      message !== "" &&
      isValidEmail(email)
    ) {
      // console.log(fullname, email, phone, entite, objet, message);

         const service_id = "service_70y_stic_congo";
         const template_id = process.env.React_App_Emailjs_templateId;
         const user_id = process.env.React_App_Emailjs_userId;

         const sendEmail = async (name, email, phone, object, message) => {
           try {
             const response = await emailjs.send(
               service_id,
               template_id,
               { name, email, phone, object, message },
               user_id
             );

             if (response.status === 200) {
               // console.log("Successfully sent message.");
               setSuccesmsg(
                 " Votre message a été envoyé avec succès! Nous vous recontacterons dans un instant ✔️"
               );
               setTimeout(() => {
                 window.location.reload(true);
               }, 5000);
             }
           } catch (error) {
             // console.error("Failed to send email. Error: ", error);
               setMessageError(
                 "Erreur de connexion, veuillez réessayer svp ⚠️ "
               );
           }
         };

         sendEmail(
           fullname + " " + "de" + " " + entite,
           email,
           phone,
           objet,
           message
         );
       
        setMessageError("");
      // Perform operation and then set loading to false
      setTimeout(() => {
        setSuccesmsg(
            "Envoie en cours..."
        );
        setFullname("");
        setEmail("");
        setPhone("");
        setObjet("");
        setEntite("");
        setMessage("");
        setLoading(false);
      }, 500);
    }
  }

  return (
    <>
      <div className="fixed top-0  w-full bg-gradient-to-r from-blue-800 to-red-500 text-white py-4 px-6 z-50">
        {/* Your fixed component content */}
        <Header />
        {/* Add navigation, buttons, or any other content */}
      </div>

      <div class="relative  py-10 overflow-hidden bg-black sm:py-16  lg:py-24 xl:py-16">
        <div class="absolute inset-0 ">
          <img
            class="object-cover container w-full h-full md:object-left md:scale-150  md:origin-top-left"
            src=".././background.gif"
            alt=""
          />
        </div>

        <div class="absolute inset-0 hidden bg-gradient-to-r md:block from-black to-transparent"></div>

        <div class="relative px-4  mx-auto sm:px-6 -mt-48  lg:px-8 max-w-12xl">
          <div class="text-center md:w-2/3 lg:w-1/2 xl:w-2/4 md:text-left">
            <div class="mobile  mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center lg:pt-32">
              <p class="mx-auto -mt-4 hover:italic hover:white max-w-2xl text-lg rounded-full border-2 border-white w-64 p-2 tracking-tight text-white sm:mt-6">
                Ingénierie
                <span class="border-b border-dotted border-slate-300 text-yellow-500 hover:text-white">
                  Informatique
                </span>
              </p>

              <h1 class="mx-auto  max-w-4xl font-display text-5xl font-medium tracking-tight text-yellow-500 sm:text-7xl">
                <span class="inline-block ">
                  <span className="animate-pulse text-center mx-auto">
                    {" "}
                    {/* Bienvenu à */}
                  </span>
                  <span class="relative whitespace-nowrap text-blue-600 animate-bounce">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 418 42"
                      class="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70 "
                      preserveAspectRatio="none"
                    >
                      <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                    </svg>
                    <span class="relative text-center mx-auto text-white ">
                      STIC
                    </span>
                  </span>
                </span>
                <span class="inline-block text-white ml-4  ">CONGO</span>
              </h1>
              <div class="title font-extrabold text-3xl md:text-3xl mt-4 [text-wrap:balance] bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 to-50% to-slate-200">
                Nous sommes dans la révolution du numérique avec :{" "}
                <span class=" text-indigo-500 inline-flex flex-col h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] md:h-[calc(theme(fontSize.4xl)*theme(lineHeight.tight))] overflow-hidden">
                  <ul class="block animate-text-slide-5 text-center -mb-4 text-yellow-500 leading-tight [&_li]:block">
                    <li>LE ERP & LE CRM </li>
                    <li>LE E-MARKETING </li>
                    <li className="text-center">L'I.A & CHATBOT</li>
                    <li>LE WEB 2.0 & 3.0</li>
                    <li>L'E-COMMERCE</li>
                    <li aria-hidden="true">LE HIGH TECH</li>
                  </ul>
                </span>
              </div>

              <p class="mx-auto mt-0 max-w-2xl text-lg tracking-tight text-white sm:mt-6">
                <span class="inline-block text-justify">
                  Société d'Ingénierie Informatique. Créé en 2016, STIC CONGO
                  est une société spécialisée dans la technologie de
                  l’information et de la communication, ainsi que la vente de
                  matériels informatiques et High-Tech.
                </span>
                <span class="space inline-block italic text-yellow-500 py-4 -mb-16">
                  Notre expérience fait la différence...
                </span>
              </p>

              <div class="mt-8 flex flex-col justify-center gap-y-5 sm:mt-10 sm:flex-row sm:gap-y-0 sm:gap-x-6">
                <a
                  class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-yellow-500 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900 animate-fade-in-left"
                  href="#"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    role="img"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    class="h-3 w-3 flex-none"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.824 7.287c.008 0 .004 0 0 0zm-2.8-1.4c.006 0 .003 0 0 0zm16.754 2.161c-.505-1.215-1.53-2.528-2.333-2.943.654 1.283 1.033 2.57 1.177 3.53l.002.02c-1.314-3.278-3.544-4.6-5.366-7.477-.091-.147-.184-.292-.273-.446a3.545 3.545 0 01-.13-.24 2.118 2.118 0 01-.172-.46.03.03 0 00-.027-.03.038.038 0 00-.021 0l-.006.001a.037.037 0 00-.01.005L15.624 0c-2.585 1.515-3.657 4.168-3.932 5.856a6.197 6.197 0 00-2.305.587.297.297 0 00-.147.37c.057.162.24.24.396.17a5.622 5.622 0 012.008-.523l.067-.005a5.847 5.847 0 011.957.222l.095.03a5.816 5.816 0 01.616.228c.08.036.16.073.238.112l.107.055a5.835 5.835 0 01.368.211 5.953 5.953 0 012.034 2.104c-.62-.437-1.733-.868-2.803-.681 4.183 2.09 3.06 9.292-2.737 9.02a5.164 5.164 0 01-1.513-.292 4.42 4.42 0 01-.538-.232c-1.42-.735-2.593-2.121-2.74-3.806 0 0 .537-2 3.845-2 .357 0 1.38-.998 1.398-1.287-.005-.095-2.029-.9-2.817-1.677-.422-.416-.622-.616-.8-.767a3.47 3.47 0 00-.301-.227 5.388 5.388 0 01-.032-2.842c-1.195.544-2.124 1.403-2.8 2.163h-.006c-.46-.584-.428-2.51-.402-2.913-.006-.025-.343.176-.389.206-.406.29-.787.616-1.136.974-.397.403-.76.839-1.085 1.303a9.816 9.816 0 00-1.562 3.52c-.003.013-.11.487-.19 1.073-.013.09-.026.181-.037.272a7.8 7.8 0 00-.069.667l-.002.034-.023.387-.001.06C.386 18.795 5.593 24 12.016 24c5.752 0 10.527-4.176 11.463-9.661.02-.149.035-.298.052-.448.232-1.994-.025-4.09-.753-5.844z"></path>
                  </svg>
                  <a href="#contact" class="ml-3">
                    Contactez-nous ⇾
                  </a>
                </a>
                <div
                  class="relative flex flex-1 flex-col items-stretch sm:flex-none"
                  data-headlessui-state=""
                >
                  <button
                    class="group inline-flex ring-1 items-center justify-center  rounded-full py-2 px-4 text-sm focus:outline-none ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300 animate-fade-in-right"
                    id="headlessui-menu-button-:r4:"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-headlessui-state=""
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      width="1em"
                      viewBox="0 0 256 256"
                    >
                      <path fill="#F1511B" d="M121.666 121.666H0V0h121.666z" />
                      <path fill="#80CC28" d="M256 121.666H134.335V0H256z" />
                      <path
                        fill="#00ADEF"
                        d="M121.663 256.002H0V134.336h121.663z"
                      />
                      <path
                        fill="#FBBC09"
                        d="M256 256.002H134.335V134.336H256z"
                      />
                    </svg>
                    <button
                      onClick={() => setShowModal(true)}
                      class="ml-3 text-white"
                    >
                      Solutions Microsoft
                    </button>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 gap-y-6 md:grid-cols-2 md:gap-x-20">
            <div id="apropos" class="">
              <h2 class="text-3xl font-bold leading-tight text-justify text-black sm:text-4xl lg:text-4.5xl">
                Développer votre activité avec STIC CONGO.
              </h2>
              <p class="mt-4 text-base leading-relaxed text-gray-600 text-justify">
                Leader dans le consulting, nous arrivons à avancer avec des
                équipes passionnées, et de croitre dans un marché avide
                d'innovations avec (03) trois partenaires reconnus en Afrique et
                ailleurs :{" "}
                <span class="text-blue-600 font-bold hover:text-red-500 hover:italic">
                I-VINSYS SERVICES II-MEDIA CONSULTING III-GEMS CONSULTING
                </span>{" "}
                . Avec cette vision 360° du domaine de l’information et de la
                communication, nous offrons. Des solutions logicielles sur
                mesure répondant aux besoins spécifiques de vos clients
              </p>
            </div>

            <div class="relative pl-20 pr-6 sm:pl-6 md:px-0">
              <div class="relative w-full max-w-xs mt-4 mb-10 ml-auto">
                <img
                  class="ml-auto transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
                  src=".././Equipe.png"
                  alt=""
                />

                <img
                  class="absolute -top-4 -left-12 "
                  src=".././wavey-lines.svg"
                  alt=""
                />

                <div class="absolute -bottom-10 -left-16">
                  <div
                    class="bg-yellow-300 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
                  >
                    <div class="px-8 py-10">
                      <span class="block text-4xl font-bold text-black lg:text-5xl">
                        {" "}
                        100%{" "}
                      </span>
                      <span class="block mt-2 text-base leading-tight text-black">
                        {" "}
                        Satisfaction
                        <br />
                        client{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-10 bg-gray-100 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-6 sm:gap-x-12 gap-y-12">
            <div class="lg:col-span-2">
              <p class="text-2xl font-bold hover:animate-pulse leading-tight text-justify text-gray-800 sm:text-2xl lg:text-3xl lg:leading-tight">
                Une équipe,
                <br />
                + De 08 ans expériences,
                <br />
                dans la digitalisation des entreprises
                <br />
              </p>
              <p class="mt-6 text-base text-justify text-gray-600">
                Notre expertise couvre tout le domaine de l'informatique et du
                numérique. Nous touchons le materiel et les logiciels.
              </p>
            </div>

            <div class="lg:col-span-3 xl:col-span-4">
              <span class="block xl:inline">
                <span class="bg-gradient-to-r from-blue-500 to-yellow-500 hover:italic text-5xl font-bold bg-clip-text text-transparent">
                  NOS CLIENTS ET PARTENAIRES
                </span>
              </span>
              <LogoCarousel logos={logos} />
              <div class="grid items-center max-w-16xl grid-cols-2 mx-auto lg:grid-cols-4 gap-x-10 gap-y-16">
                {/* <LogoCarousel logos={logos} /> */}
              </div>
              <div class="flex items-center justify-start mt-10 space-x-3 lg:hidden">
                <div class="w-2.5 h-2.5 rounded-full bg-blue-600 block"></div>
                <div class="w-2.5 h-2.5 rounded-full bg-gray-300 block"></div>
                <div class="w-2.5 h-2.5 rounded-full bg-gray-300 block"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SERVIVE */}
      <div id="services" class="bg-gray-700 p-4 min-h-screen">
        <div class="max-w-7xl mx-auto h-max px-6 md:px-12 xl:px-6">
          <div class="md:w-2/3 lg:w-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6 text-gray-100"
            >
              <path
                fill-rule="evenodd"
                d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <h2 class="my-8 text-2xl font-bold text-white md:text-4xl">
              NOS SERVICES
            </h2>
            <p class="text-gray-300">
              Les diffétents Services que nous offrons pour transformer votre
              entreprise vis à vis de vos conccurents
            </p>
          </div>
          <div class="mt-16 grid divide-x divide-y  divide-gray-700 overflow-hidden rounded-3xl border text-gray-600 border-gray-700 sm:grid-cols-2 lg:grid-cols-4  lg:divide-y-0 xl:grid-cols-4">
            <div class="group relative hover:bg-black bg-yellow-500 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div
                class="relative space-y-8 py-12 p-8 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  color="white"
                >
                  <path
                    fill="currentColor"
                    d="M5.59 3.41L7 4.82L3.82 8L7 11.18L5.59 12.6L1 8l4.59-4.59m5.82 0L16 8l-4.59 4.6L10 11.18L13.18 8L10 4.82l1.41-1.41M22 6v12c0 1.11-.89 2-2 2H4a2 2 0 0 1-2-2v-4h2v4h16V6h-2.97V4H20c1.11 0 2 .89 2 2Z"
                  />
                </svg>
                <div class="space-y-2 ">
                  <h5 class="text-xl font-semibold text-white transition">
                    Developpement web
                  </h5>
                  <p class="text-gray-100 text-justify">
                    Conçoit, développe et met au point, notre service
                    informatique fort sa méthodologie Agile Scrum exécute avec
                    minutie les projets d'application informatique, de la phase
                    d'étude à son intégration, pour vous et selon vos besoins
                    fonctionnels et vos cahiers de charge
                  </p>
                </div>
              </div>
            </div>
            <div class="group relative bg-gray-800 hover:bg-blue-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div
                class="relative space-y-8 py-12 p-8 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 32 32"
                  color="white"
                >
                  <path
                    fill="currentColor"
                    d="M27 29H13c-1.1 0-2-.9-2-2v-4h2v4h14V13h-4v-2h4c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2z"
                  />
                  <path
                    fill="currentColor"
                    d="M11 19h10v2H11zm0-4h10v2H11zm0-4h10v2H11z"
                  />
                  <path
                    fill="currentColor"
                    d="M5 3h14c1.1 0 2 .9 2 2v4h-2V5H5v14h4v2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2z"
                  />
                </svg>
                <div class="space-y-2">
                  <h5 class="text-xl font-semibold text-white transition group-hover:text-secondary">
                    Web Hosting
                  </h5>
                  <p class="text-gray-300 text-justify">
                    Trouver un hébergeur de qualité et sécurisé s’avère être de
                    plus incontournable pour des internautes de plus exigeants.
                    Nos offres, vous garantit une offre qui tient en compte la
                    fluidité avec des serveurs de nouvelles générations
                  </p>
                </div>
              </div>
            </div>
            <div class="group relative bg-black hover:bg-yellow-500 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div
                class="relative space-y-8 py-12 p-8 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  color="white"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    d="M6 6V5c0-3 1.5-4 4-4h4c2.5 0 4 1.5 4 4v1c3 0 5 2 5 5s-2 5-5 5M14 6H6c-3 0-5 1.5-5 5s2 5 5 5m2 3h8v-7H8v7Zm4 0v4v-4Zm-3 4h6h-6Z"
                  />
                </svg>
                <div class="space-y-2">
                  <h5 class="text-xl font-semibold text-white transition group-hover:text-secondary">
                    Cloud Computing
                  </h5>
                  <p class="text-gray-100 text-justify">
                    Transformer votre infrastructure informatique en service,
                    notre système de cloud computing vous offre la plateforme de
                    cloud public la plus ouverte et la plus sécurisée pour
                    l'entreprise, une plateforme hybride multicloud de nouvelle
                    génération, des fonctions avancées de données et d'IA
                  </p>
                </div>
              </div>
            </div>
            <div class="group relative bg-blue-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div
                class="relative space-y-8 py-12 p-8 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  color="white"
                >
                  <path
                    fill="currentColor"
                    d="M5.575 2.074A.75.75 0 0 1 6 2.75c0 .83.312 1.308.703 1.89l.029.042c.332.494.768 1.141.768 2.068c0 .946-.477 1.78-1.204 2.275c.191.16.36.357.506.593c.476.77.698 1.947.698 3.637c0 1.712-.23 3.768-.605 5.41c-.186.816-.417 1.569-.692 2.134a3.043 3.043 0 0 1-.51.77c-.206.211-.523.436-.943.436c-.42 0-.737-.225-.942-.436a2.995 2.995 0 0 1-.51-.77c-.276-.566-.507-1.318-.693-2.135A26.05 26.05 0 0 1 2 13.254c0-1.69.222-2.866.698-3.636a2.44 2.44 0 0 1 .506-.593A2.747 2.747 0 0 1 2 6.75c0-.368 0-1.001.383-1.818c.375-.8 1.088-1.719 2.398-2.768a.75.75 0 0 1 .794-.09ZM10 14.917a5.957 5.957 0 0 1-1.532-.476c.021-.406.032-.804.032-1.186c0-.406-.012-.794-.038-1.164c.443.365.966.636 1.538.783V10.5A1.5 1.5 0 0 1 11.5 9H15a4 4 0 0 0-6.585-3.053c-.173-.814-.575-1.41-.832-1.791l-.04-.06A6 6 0 0 1 17 9h3.5a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-4.583Zm2 0V19h8v-8h-3.341a6.008 6.008 0 0 1-4.66 3.917ZM14.465 11H12v1.874A4.008 4.008 0 0 0 14.465 11Z"
                  />
                </svg>
                <div class="space-y-2">
                  <h5 class="text-xl font-semibold text-white transition group-hover:text-secondary">
                    Graphisme
                  </h5>
                  <p class="text-gray-300 text-justify">
                    Nous travaillons tous les aspects de votre marque pour créer
                    une identité visuelle originale Logotype, Charte graphique,
                    Plaquettes, Catalogues, Brochures, Flyers, Dépliants,
                    Affiches...
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-16 grid divide-x divide-y  divide-gray-700 overflow-hidden rounded-3xl border text-gray-600 border-gray-700 sm:grid-cols-2 lg:grid-cols-4  lg:divide-y-0 xl:grid-cols-4">
            <div class="group relative bg-red-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div
                class="relative space-y-8 py-12 p-8 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 24 24"
                  color="white"
                >
                  <path
                    fill="currentColor"
                    d="M18.918 17.48c-.126 2.727-2.384 4.696-5.364 4.696H7.34v-6.123l-2.185-.957V24h8.381c4.334 0 7.549-2.962 7.549-6.881v-.163c-.65.235-1.372.415-2.167.524Zm1.355-9.501C18.611 4.313 17.726.989 15.432.213c-1.336-.452-2.005-.091-2.637.217a.306.306 0 0 0-.072.505c.361.307.813.687 1.336 1.174c-1.95-1.138-7.333-2.835-7.874-.776c-.488 1.86-1.319 4.587-1.319 4.587S.603 5.487.116 7.293c-.488 1.806 3.323 5.274 9.627 7.134c6.303 1.861 11.198 1.373 13.311-.921c2.113-2.294.072-5.473-2.781-5.527Zm-1.247.036c-.487.47-2.077 1.68-5.563 1.427c-3.738-.271-6.809-2.474-7.604-3.088a.337.337 0 0 1-.126-.398c.054-.18.126-.469.253-.849c.072-.234.343-.343.542-.216c1.571.903 4.1 2.221 6.791 2.402c2.402.163 3.847-.542 4.786-1.066a.389.389 0 0 1 .542.199l.47 1.156c.036.162.018.325-.091.433Z"
                  />
                </svg>
                <div class="space-y-2">
                  <h5 class="text-xl font-semibold text-white transition">
                    Cybersécurité
                  </h5>
                  <p class="text-gray-300 text-justify">
                    Savoir vos données personnelles et professionnelles en
                    sécurité est primordiale de nos jours, avec STIC, nous
                    sommes des avant-gardistes des solutions sécurité parce que
                    les hackers sont de plus en plus innovants
                  </p>
                </div>
              </div>
            </div>
            <div class="group relative bg-gray-800/50 hover:bg-black transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div
                class="relative space-y-8 py-12 p-8 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 48 48"
                  color="white"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M6 6h31v5h-2V8H8v23h21.387v2H6V6Zm30 13a3 3 0 1 0 0-6a3 3 0 0 0 0 6Zm2.031 2.01c1.299 0 2.327.584 3 1.486c.629.845.895 1.89.955 2.855a7.626 7.626 0 0 1-.397 2.92c-.3.87-.807 1.77-1.589 2.387V40.5a1.5 1.5 0 0 1-2.98.247L35.73 33h-.298l-1.458 7.776A1.5 1.5 0 0 1 31 40.5V26.233a63.223 63.223 0 0 0-.592.919l-.078.123l-.02.032l-.005.009a1.5 1.5 0 0 1-1.274.707h-5a1.5 1.5 0 1 1 0-3h4.177c.243-.376.563-.864.899-1.354c.35-.511.736-1.052 1.08-1.476c.167-.207.354-.423.542-.6c.092-.087.22-.2.376-.3a1.72 1.72 0 0 1 .926-.282h6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div class="space-y-2">
                  <h5 class="text-xl font-semibold text-white transition group-hover:text-secondary">
                    Formation
                  </h5>
                  <p class="text-gray-300 text-justify">
                    Former, informer et garder nos étudiants aux premières
                    lignes des informations et des connaissances pour leurs
                    orientations professionnelles.
                  </p>
                </div>
              </div>
            </div>
            <div class="group relative bg-blue-500 hover:bg-yellow-500 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div
                class="relative space-y-8 py-12 p-8 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 48 48"
                  color="white"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M6 20.5C6 12.492 12.492 6 20.5 6h7C35.508 6 42 12.492 42 20.5S35.508 35 27.5 35h-.7v7S6 38.5 6 20.5Zm21.691-8.018c1.03.684 1.807 1.793 1.807 3.28c0 1.578-.637 2.738-1.668 3.448a4.524 4.524 0 0 1-1.33.614v1.532a1.5 1.5 0 1 1-3 0v-2.797a1.5 1.5 0 0 1 1.4-1.497c.554-.037.974-.147 1.228-.322a.699.699 0 0 0 .24-.272c.06-.12.13-.334.13-.707c0-.312-.123-.552-.468-.782c-.392-.26-1.022-.452-1.779-.476c-.748-.024-1.475.122-2.01.381c-.536.26-.732.552-.788.767a1.5 1.5 0 1 1-2.905-.746c.344-1.341 1.348-2.217 2.385-2.72c1.04-.505 2.263-.718 3.414-.681c1.142.036 2.362.324 3.344.978ZM25 29a2 2 0 1 0 0-4a2 2 0 0 0 0 4Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div
                  class="space-y-2 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
                >
                  <h5 class="text-xl font-semibold text-white transition group-hover:text-secondary">
                    Support IT
                  </h5>
                  <p class="text-gray-300 text-justify">
                    Notre support IT assure la maintenance du materiel et la
                    mise des logiciels informatiques dans votre entreprise.
                    Déléguez-nous votre service support SI
                  </p>
                </div>
              </div>
            </div>
            <div class="group relative bg-red-500 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div
                class="relative space-y-8 py-12 p-8 transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 48 48"
                  color="white"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M39.07 10.1L26.28 4.92a5.61 5.61 0 0 0-4.26 0L8.89 10.1A2 2 0 0 0 7.62 12v12.47c0 11.16 11.6 19 16.58 19s16.18-7.87 16.18-19V12a2 2 0 0 0-1.31-1.9ZM24 4.5v39M7.62 24.47h32.76"
                  />
                </svg>
                <div class="space-y-2">
                  <h5 class="text-xl font-semibold text-white transition group-hover:text-secondary">
                    Audit Informatique
                  </h5>
                  <p class="text-gray-100 text-justify">
                    Assurer vous que tout votre système d'information marche
                    très bien et prévoyez les risques d'attaque et de perte de
                    données.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SERVIVE */}
      {/* GROUP  */}
      <div class="expertiseone bg-white  dark:bg-gray-800 h-screen h-full py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div class="mb-4 flex items-center justify-between gap-8 sm:mb-8 md:mb-12">
            <div class="flex items-center gap-12">
              <h2 class="text-2xl  font-bold text-gray-800 lg:text-3xl dark:text-white">
                Innovations numériques
              </h2>

              <p class="hidden max-w-screen-sm text-gray-500 dark:text-gray-300 md:block">
                Des solutions sur mesure pour la digitalisation de tout le
                processus de vente de vos produits & services.
              </p>
            </div>

            <a
              href="#"
              class="inline-block rounded-lg border bg-red-500/50 dark:bg-red-700 dark:border-none px-4 py-2 text-center text-sm font-semibold text-gray-100 dark:text-gray-200 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:px-8 md:py-3 md:text-base"
            >
              WEB 2.0
            </a>
          </div>

          <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
            <a
              href="#"
              class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"
            >
              <img
                src=".././1.jpg"
                loading="lazy"
                alt="Photo by Minh Pham"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
              />

              <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>

              <span class="relative ml-4 mb-3 inline-block rounded-full border-2 p-2 text-sm text-white md:ml-5 md:text-lg">
                Visuels Communications
              </span>
            </a>

            <a
              href="#"
              class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80"
            >
              <img
                src=".././2.png"
                loading="lazy"
                alt="Photo by Magicle"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
              />

              <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>

              <span class="relative ml-4  rounded-full border-2 p-2 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                Logotype
              </span>
            </a>

            <a
              href="#"
              class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80"
            >
              <img
                src=".././3.png"
                loading="lazy"
                alt="Photo by Martin Sanchez"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
              />

              <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>

              <span class="relative ml-4 mb-3 rounded-full border-2 p-2 inline-block text-sm text-white md:ml-5 md:text-lg">
                Webdesigner
              </span>
            </a>

            <a
              href="#"
              class="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80"
            >
              <img
                src=".././4.png"
                loading="lazy"
                alt="Photo by Lorenzo Herrera"
                class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"
              />

              <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>

              <span class="relative ml-4 rounded-full border-2 p-2 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                Visuels & Magazines
              </span>
            </a>
          </div>
        </div>
      </div>
      <div id="expertises" class="  py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class=" max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight mt-8 text-black sm:text-4xl lg:text-5xl">
              NOS EXPERTISES
            </h2>
            <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
              Nous donnons corps à vos projets en fonction de vos besoins et
              exigences. Toutes nos solutions prennent en compte les normes, le
              goût du client sous l'oeil des nos Experts confirmés
            </p>
          </div>

          <div class="grid grid-cols-1 gap-6 mt-12 lg:mt-16 xl:gap-10 sm:grid-cols-2 lg:grid-cols-3">
            <div
              class="overflow-hidden bg-white rounded shadow transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
            >
              <div class="p-8">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 256 242"
                  >
                    <defs>
                      <linearGradient
                        id="logosMicrosoftAzure0"
                        x1="58.972%"
                        x2="37.191%"
                        y1="7.411%"
                        y2="103.762%"
                      >
                        <stop offset="0%" stop-color="#114A8B" />
                        <stop offset="100%" stop-color="#0669BC" />
                      </linearGradient>
                      <linearGradient
                        id="logosMicrosoftAzure1"
                        x1="59.719%"
                        x2="52.691%"
                        y1="52.313%"
                        y2="54.864%"
                      >
                        <stop offset="0%" stop-opacity=".3" />
                        <stop offset="7.1%" stop-opacity=".2" />
                        <stop offset="32.1%" stop-opacity=".1" />
                        <stop offset="62.3%" stop-opacity=".05" />
                        <stop offset="100%" stop-opacity="0" />
                      </linearGradient>
                      <linearGradient
                        id="logosMicrosoftAzure2"
                        x1="37.279%"
                        x2="62.473%"
                        y1="4.6%"
                        y2="99.979%"
                      >
                        <stop offset="0%" stop-color="#3CCBF4" />
                        <stop offset="100%" stop-color="#2892DF" />
                      </linearGradient>
                    </defs>
                    <path
                      fill="url(#logosMicrosoftAzure0)"
                      d="M85.343.003h75.753L82.457 233a12.078 12.078 0 0 1-11.442 8.216H12.06A12.06 12.06 0 0 1 .633 225.303L73.898 8.219A12.08 12.08 0 0 1 85.343 0v.003Z"
                    />
                    <path
                      fill="#0078D4"
                      d="M195.423 156.282H75.297a5.56 5.56 0 0 0-3.796 9.627l77.19 72.047a12.138 12.138 0 0 0 8.28 3.26h68.02l-29.568-84.934Z"
                    />
                    <path
                      fill="url(#logosMicrosoftAzure1)"
                      d="M85.343.003a11.98 11.98 0 0 0-11.471 8.376L.723 225.105a12.045 12.045 0 0 0 11.37 16.112h60.475a12.926 12.926 0 0 0 9.921-8.437l14.588-42.991l52.105 48.6a12.327 12.327 0 0 0 7.757 2.828h67.766l-29.721-84.935l-86.643.02L161.37.003H85.343Z"
                    />
                    <path
                      fill="url(#logosMicrosoftAzure2)"
                      d="M182.098 8.207A12.06 12.06 0 0 0 170.67.003H86.245c5.175 0 9.773 3.301 11.428 8.204L170.94 225.3a12.062 12.062 0 0 1-11.428 15.92h84.429a12.062 12.062 0 0 0 11.425-15.92L182.097 8.207Z"
                    />
                  </svg>
                  <div class="ml-5 mr-auto">
                    <p class="text-xl font-semibold text-black hover:text-white">
                      AZURE
                    </p>
                    <p class="mt-px text-sm text-gray-600 hover:text-white">
                      Cloud computing
                    </p>
                  </div>
                  <svg
                    class="hidden w-5 h-5 text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
                <p class="text-base leading-relaxed text-justify text-gray-600 mt-7 hover:text-white">
                  Offrons des solutions basées sur Azure pour des services cloud
                  évolutifs. Boostez l'efficacité opérationnelle avec des
                  fonctionnalités de stockage, d'analyse de données et de
                  développement d'applications, optimisant la flexibilité et la
                  performance de l'infrastructure informatique.
                </p>
              </div>
            </div>

            <div
              class="overflow-hidden bg-white rounded shadow transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 hover:text-white"
            >
              <div class="p-8">
                <div class="flex items-center">
                  <img
                    class="flex-shrink-0 w-16 h-auto"
                    src=".././iaas-intro-01.png"
                    alt=""
                  />
                  <div class="ml-5 mr-auto">
                    <p class="text-xl font-semibold text-black hover:text-white">
                      IAAS
                    </p>
                    <p class="mt-px text-sm text-gray-600 hover:text-white">
                      L'Infrastructure as a Service
                    </p>
                  </div>
                  <svg
                    class="block w-6 h-6 text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
                <p class="text-base leading-relaxed text-justify text-gray-600 mt-7 hover:text-white">
                  Proposons des solutions IaaS pour une infrastructure cloud
                  évolutive. Offrez à vos clients des ressources flexibles,
                  telles que des serveurs virtuels et du stockage, permettant
                  une gestion efficiente et économique de leur infrastructure
                  informatique
                </p>
              </div>
            </div>

            <div
              class="overflow-hidden bg-white rounded shadow transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 hover:text-white"
            >
              <div class="p-8">
                <div class="flex items-center">
                  <img
                    class="flex-shrink-0 w-12 h-auto"
                    src=".././MicrosoftTeams-image.png"
                    alt=""
                  />
                  <div class="ml-5 mr-auto">
                    <p class="text-xl font-semibold text-black hover:text-white">
                      SAAS
                    </p>
                    <p class="mt-px text-sm text-gray-600 hover:text-white">
                      Software as a service
                    </p>
                  </div>
                  <svg
                    class="hidden w-5 h-5 text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
                <p class="text-base leading-relaxed text-justify text-gray-600 mt-7 hover:text-white">
                  Offrons des solutions SaaS (Software as a Service) pour des
                  applications cloud accessibles via Internet. Simplifiez la
                  gestion logicielle, améliorez l'accessibilité et réduisez les
                  coûts, offrant à vos clients une expérience logicielle
                  pratique et scalable.
                </p>
              </div>
            </div>

            <div
              class="overflow-hidden bg-white rounded shadow transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 hover:text-white"
            >
              <div class="p-8">
                <div class="flex items-center">
                  <img
                    class="flex-shrink-0 w-16 h-auto"
                    src=".././aws.png"
                    alt=""
                  />
                  <div class="ml-5 mr-auto">
                    <p class="text-xl font-semibold text-black hover:text-white">
                      AWS
                    </p>
                    <p class="mt-px text-sm text-gray-600 hover:text-white">
                      Amazon Web Services
                    </p>
                  </div>
                  <svg
                    class="hidden w-5 h-5 text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
                <p class="text-base leading-relaxed text-gray-600 mt-7 hover:text-white text-justify">
                  Proposons des solutions basées sur AWS (Amazon Web Services)
                  pour une puissance cloud incomparable. Intégrez des services
                  évolutifs, de stockage, d'IA et bien plus encore, optimisant
                  l'agilité opérationnelle et la performance de l'infrastructure
                  informatique de vos clients.
                </p>
              </div>
            </div>

            <div
              class="overflow-hidden bg-white rounded shadow transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 hover:text-white"
            >
              <div class="p-8">
                <div class="flex items-center">
                  <img
                    class="flex-shrink-0 w-16 h-auto"
                    src=".././microsoft-power-bi.webp"
                    alt=""
                  />
                  <div class="ml-5 mr-auto">
                    <p class="text-xl font-semibold text-black hover:text-white">
                      POWER BI
                    </p>
                    <p class="mt-px text-sm text-gray-600 hover:text-white">
                      Visualisations de données
                    </p>
                  </div>
                  <svg
                    class="hidden w-5 h-5 text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
                <p class="text-base leading-relaxed text-justify hover:text-white text-gray-600 mt-7">
                  Proposez des solutions Power BI pour l'analyse de données
                  dynamique. Intégrez des tableaux de bord interactifs, des
                  rapports visuels et des insights exploitables, permettant à
                  vos clients de prendre des décisions éclairées pour leur
                  entreprise
                </p>
              </div>
            </div>

            <div
              class="overflow-hidden bg-white rounded shadow transition duration-500 ease-in-out hover:bg-blue-500 transform 
                    hover:-translate-y-1 hover:scale-110 "
            >
              <div class="p-8">
                <div class="flex items-center">
                  <img
                    class="flex-shrink-0 w-12 h-auto"
                    src=".././artificial-intelligence.png"
                    alt=""
                  />
                  <div class="ml-5 mr-auto">
                    <p class="text-xl hover:text-white font-semibold text-black ">
                      MACHINE LEARNING
                    </p>
                    <p class="mt-px text-sm hover:text-white text-gray-600">
                      Intelligence artificielle (IA)
                    </p>
                  </div>
                  <svg
                    class="hidden w-5 h-5 text-blue-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
                <p class="text-base leading-relaxed text-gray-600 text-justify hover:text-white mt-7">
                  Offrez des solutions de Machine Learning pour des analyses
                  prédictives avancées. Intégrez des modèles intelligents,
                  optimisez la prise de décision et améliorez la performance
                  opérationnelle de vos clients grâce à des solutions innovantes
                  basées sur l'apprentissage automatique
                </p>
              </div>
            </div>
          </div>

          <div class="mt-12 text-center"></div>
        </div>
      </div>

      <div class="py-10 bg-gray-100 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div id="contact" class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Nous joindre ?
            </h2>
            <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-500">
              Travaillons ensemble et oeuvrons pour le développement de
              l'economie numérique au Congo.
            </p>
          </div>

          <div class="max-w-5xl mx-auto mt-12 sm:mt-16">
            <div class="grid grid-cols-1 gap-6 px-8 text-center md:px-0 md:grid-cols-3">
              <div class="overflow-hidden bg-white rounded-xl">
                <div class="p-6">
                  <svg
                    class="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <p class="mt-6 text-lg font-medium text-gray-900">
                    +(242) 06 85 81 061
                  </p>
                  <p class="mt-1 text-lg font-medium text-gray-900">
                    +(243) 83 46 21 364
                  </p>
                </div>
              </div>

              <div class="overflow-hidden bg-white rounded-xl">
                <div class="p-6">
                  <svg
                    class="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <p class="mt-6 text-lg font-medium text-gray-900">
                    contact@stic-congo.com
                  </p>
                </div>
              </div>

              <div class="overflow-hidden bg-white rounded-xl">
                <div class="p-6">
                  <svg
                    class="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <p class="mt-6 text-lg font-medium leading-relaxed text-gray-900">
                    Rond-Point La Coupole Immeuble Ebata. République du Congo
                  </p>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 gap-6 px-8 mt-8 text-center md:px-0 md:grid-cols-3">
              <div class="overflow-hidden bg-white rounded-xl">
                <div class="p-6">
                  <svg
                    class="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <p class="mt-6 text-lg font-medium text-gray-900">
                    +(44) 74 35 52 22 42
                  </p>
                  {/* <p class="mt-1 text-lg font-medium text-gray-900">
                    +(44) 83 46 21 364
                  </p> */}
                </div>
              </div>

              <div class="overflow-hidden bg-white rounded-xl">
                <div class="p-6">
                  <svg
                    class="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <p class="mt-6 text-lg font-medium text-gray-900">
                    contact@stic-congo.com
                  </p>
                </div>
              </div>

              <div class="overflow-hidden bg-white rounded-xl">
                <div class="p-6">
                  <svg
                    class="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <p class="mt-6 text-lg font-medium leading-relaxed text-gray-900">
                    71-75 Shelton Street, Covent Garden, Londres, Angleterre
                  </p>
                </div>
              </div>
            </div>

            <div class="mt-6 overflow-hidden bg-white rounded-xl">
              <div class="px-6 py-12 sm:p-12">
                <h3 class="text-3xl font-semibold text-center text-gray-900">
                  Envoyer un message
                </h3>
                <p className="text-2xl text-semibold p-4 text-center mx-auto text-red-400">
                  {" "}
                  {messageError}{" "}
                </p>
                <p class="text-center p-2 text-2xl text-green-500">
                  {succesmsg}{" "}
                </p>
                <div className="mt-14">
                  <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
                    <div>
                      <label for="" class="text-base font-medium text-gray-900">
                        {" "}
                        Nom et Prénom(s){" "}
                      </label>
                      <div class="mt-2.5 relative">
                        <input
                          type="text"
                          name="fullname"
                          value={fullname}
                          onChange={(e) => {
                            setFullname(e.target.value);
                          }}
                          id="fullnme"
                          placeholder="Christian LUALUA"
                          class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                        />
                      </div>
                    </div>

                    <div>
                      <label for="" class="text-base font-medium text-gray-900">
                        {" "}
                        Votre E-mail{" "}
                      </label>
                      <div class="mt-2.5 relative">
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          id="email"
                          placeholder="exemple@exemple.com"
                          class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                        />
                      </div>
                    </div>

                    <div>
                      <label for="" class="text-base font-medium text-gray-900">
                        {" "}
                        Numéro whatsApp{" "}
                      </label>
                      <div class="mt-2.5 relative">
                        <input
                          type="text"
                          name="phone"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                          id="phone"
                          placeholder="+24201020304"
                          class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                        />
                      </div>
                    </div>

                    <div>
                      <label for="" class="text-base font-medium text-gray-900">
                        {" "}
                        Nom de votre Société{" "}
                      </label>
                      <div class="mt-2.5 relative">
                        <input
                          type="text"
                          name="entite"
                          value={entite}
                          onChange={(e) => {
                            setEntite(e.target.value);
                          }}
                          id="entite"
                          placeholder="Congo Oil"
                          class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                        />
                      </div>
                    </div>
                    <div class="sm:col-span-2">
                      <label for="" class="text-base font-medium text-gray-900">
                        {" "}
                        Objet{" "}
                      </label>
                      <div class="mt-2.5 relative">
                        <input
                          name="objet"
                          id="objet"
                          value={objet}
                          onChange={(e) => {
                            setObjet(e.target.value);
                          }}
                          placeholder="Prise de contact..."
                          class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus:outline-none focus:border-blue-600 caret-blue-600"
                          rows="4"
                        ></input>
                      </div>
                    </div>

                    <div class="sm:col-span-2">
                      <label for="" class="text-base font-medium text-gray-900">
                        {" "}
                        Message{" "}
                      </label>
                      <div class="mt-2.5 relative">
                        <textarea
                          name="message"
                          id="message"
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                          placeholder="Bonjour..."
                          class="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus:outline-none focus:border-blue-600 caret-blue-600"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>

                    <div class="sm:col-span-2">
                      <button
                        type="submit"
                        onClick={handleClick}
                        enable={loading}
                        class="inline-flex items-center justify-center w-full px-4 py-4 mt-2 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md focus:outline-none hover:bg-blue-700 focus:bg-blue-700"
                      >
                        {loading ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 animate-spin mx-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                          </svg>
                        ) : (
                          "Valider"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto w-full">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl text-gray-700 mx-auto ">
                    {/* TEST DE MESSAGE */}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}

                <div class="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
                  <div class="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col mb-5 sm:mb-10">
                    <h1 class="text-4xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-6xl text-center text-gray-800 dark:text-white font-black leading-10">
                      Notre
                      <span class="flex  space-x-4 text-violet-800 dark:text-violet-500 ml-2 mr-2">
                        packs Microsoft
                        <img src=".././Logo.stic.jpg" class="w-16 mb-3 ml-4 " />
                      </span>
                    </h1>
                  </div>
                  <div class="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10">
                    <div class="border-r border-b border-l border-gray-400 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                      <img src=".././microsoft-pro.webp" class="w-full mb-3" />
                      <div class="p-4 pt-2">
                        <div class="mb-8">
                          <p class="text-sm text-gray-600 flex items-center">
                            <svg
                              class="fill-current text-gray-500 w-3 h-3 mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"></path>
                            </svg>
                            Solution entreprise
                          </p>
                          <a
                            href="#"
                            class="text-gray-900 font-bold text-lg mb-2 hover:text-indigo-600 inline-block"
                          >
                            Microsoft Dynamic 365
                          </a>
                          <p class="text-gray-700 text-sm text-justify">
                            Une solution pour la gestion de vos opérations. Une
                            solution pour vous simplifier les process
                          </p>
                        </div>
                        <div class="flex items-center">
                          <a href="#">
                            <img
                              class="w-10 h-10 rounded-full mr-4"
                              src=".././dynamics-365-microsoft.png"
                              alt="Microdoft"
                            />
                          </a>
                          <div class="text-sm">
                            <a
                              href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour STIC Congo. J'aimerais avoir plus d'info sur Microsoft Dynamic 365"
                              target="_black"
                              class="text-gray-900 font-semibold leading-none hover:text-indigo-600"
                            >
                              En savoir plus ⇾
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="border-r border-b border-l border-gray-400 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                      <img src=".././microsoft-pro.webp" class="w-full mb-3" />
                      <div class="p-4 pt-2">
                        <div class="mb-8">
                          <p class="text-sm text-gray-600 flex items-center">
                            <svg
                              class="fill-current text-gray-500 w-3 h-3 mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"></path>
                            </svg>
                            Solution entreprise
                          </p>
                          <a
                            href="#"
                            class="text-gray-900 font-bold text-lg mb-2 hover:text-indigo-600 inline-block"
                          >
                            Microsoft Dynamic NAV
                          </a>
                          <p class="text-gray-700 text-sm text-justify">
                            La solution optimale de développement et d'expansion
                            de votre entreprise
                          </p>
                        </div>
                        <div class="flex items-center">
                          <a href="#">
                            <img
                              class="w-10 h-10 rounded-full mr-4"
                              src=".././Microsoft-Dynamics-NAV.png"
                              alt="Microsoft"
                            />
                          </a>
                          <div class="text-sm">
                            <a
                              href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour STIC Congo. J'aimerais avoir plus d'info sur Microsoft Dynamic NAV"
                              target="_black"
                              class="text-gray-900 font-semibold leading-none hover:text-indigo-600"
                            >
                              En savoir plus ⇾
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="border-r border-b border-l border-gray-400 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                      <img src=".././microsoft-pro.webp" class="w-full mb-3" />
                      <div class="p-4 pt-2">
                        <div class="mb-8">
                          <p class="text-sm text-gray-600 flex items-center">
                            <svg
                              class="fill-current text-gray-500 w-3 h-3 mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"></path>
                            </svg>
                            Solution entreprise
                          </p>
                          <a
                            href="#"
                            class="text-gray-900 font-bold text-lg mb-2 hover:text-indigo-600 inline-block"
                          >
                            Microsoft Dynamic CRM
                          </a>
                          <p class="text-gray-700 text-sm">
                            L'outil de gestion parfaite pour votre structure.
                            Mieux gérer le cycle de vie de vos clients
                          </p>
                        </div>
                        <div class="flex items-center">
                          <a href="#">
                            <img
                              class="w-10 h-10 rounded-full mr-4"
                              src=".././microsoft-dynamics-crm.png"
                              alt="Avatar of Jonathan Reinink"
                            />
                          </a>
                          <div class="text-sm">
                            <a
                              href="https://api.whatsapp.com/send/?phone=243834621364&text=Bonjour STIC Congo. J'aimerais avoir plus d'info sur Microsoft Dynamic CRM"
                              target="_black"
                              class="text-gray-900 font-semibold leading-none hover:text-indigo-600"
                            >
                              En savoir plus ⇾
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Accueil;
