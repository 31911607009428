import { useState, useEffect } from "react";


function Header() {

      const [showModal, setShowModal] = useState(false);
      const [loading, setLoading] = useState(false);
  return (
    <header class="text-slate-700 relative bg-gradient-to-r from-blue-800 to-red-500  flex text-white max-full flex-col overflow-hidden px-4 py-4 lg:mx-auto lg:flex-row lg:items-center">
      <a
        href="/"
        class="flex items-center whitespace-nowrap text-2xl font-black"
      >
        <span class="flex mr-2 w-16">
          {/* <img src=".././Logo-St.png" alt="" /> */}
          <img src="https://www.stic-congo.com/Logo-St.png" alt="" />

          <p className="text-3xl bg-gradient-to-r from-blue-500 to-yellow-500 text-3xl font-bold bg-clip-text text-transparent hover:text-white">
            {" "}
            STIC CONGO{" "}
          </p>
        </span>
        {/* spline */}
      </a>
      <input
        type="checkbox"
        class="peer hidden text-white"
        id="navbar-open"
        achecked
      />
      <label
        class="absolute top-5 right-5 cursor-pointer lg:hidden"
        for="navbar-open"
      >
        <span class="sr-only">Toggle Navigation</span>
        <svg
          class="h-7 w-7"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </label>
      <nav
        aria-label="Header Navigation"
        class="peer-checked:pt-8 peer-checked:max-h-60 flex max-h-0 w-full flex-col items-center overflow-hidden transition-all lg:ml-24 lg:max-h-full lg:flex-row"
      >
        <ul class="flex w-full flex-col items-center space-y-8 lg:flex-row lg:justify-center lg:space-y-0">
          <li class="lg:mr-12">
            <a
              class="rounded-lg text-yellow-500 border hover:border-yellow-500 font-bold border-white p-2  transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2"
              href="/"
            >
              Accueil
            </a>
          </li>
          <li class="lg:mr-8">
            <a
              class="rounded-lg text-yellow-500 font-bold border border-white  p-2 hover:border-yellow-500 transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2"
              href="../#apropos"
            >
              A propos
            </a>
          </li>
          <li class="lg:mr-8">
            <a
              href="../#services"
              class="rounded-lg text-yellow-500 font-bold border border-white p-2 hover:border-yellow-500  transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2"
            >
              {" "}
              Nos services
            </a>
          </li>
          <li class="lg:mr-12">
            <a
              class="rounded-lg text-yellow-500 font-bold border border-white p-2 hover:border-yellow-500  transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2"
              href="./emarketing"
            >
              E-marketing
            </a>
          </li>
          <li class="lg:mr-12">
            <a
              class="rounded-lg text-yellow-500 font-bold border border-white p-2 hover:border-yellow-500  transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2"
              href="../#expertises"
            >
              Nos expertises
            </a>
          </li>

          <li class="lg:mr-12">
            <button
              onClick={() => setShowModal(true)}
              class="rounded-lg text-yellow-500 font-bold border border-white p-2 hover:border-yellow-500  transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2"
              href="#faqs"
            >
              Emploi
            </button>
          </li>
        </ul>
        <hr class="mt-4 w-full lg:hidden" />
        <div class="my-4 flex items-center space-x-6 space-y-2 lg:my-0 lg:ml-auto lg:space-x-8 lg:space-y-0">
          <a
            href="/Golive"
            // onClick={() => setShowModal(true)}
            title="Connexion"
            class="whitespace-nowrap flex rounded-xl bg-yellow-500 px-5 py-3 font-medium text-white transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 hover:bg-blue-600"
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M7 11l5 5l5-5m-5-7v12"
              />
            </svg>
            GoLive ⇾{" "}
          </a>
          {/* <a
            href="https://wasenderpro.5sender.com/"
            target="_blank"
            title=""
            class="whitespace-nowrap rounded-xl bg-white px-5 py-3 font-medium text-blue-800 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 hover:bg-yellow-500"
          >
            WASENDERPRO ⇾
          </a> */}
        </div>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto w-full">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl text-gray-700 mx-auto ">
                      {/* TEST DE MESSAGE */}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}

                  <div class="bg-white rounded-lg shadow-lg p-6 border-2">
                    <div class="text-lg  text-center mx-auto font-medium text-center text-gray-700 mb-6"></div>
                    {/* GROUP 1 */}
                    <div class="bg-gradient-to-r from-yellow-500 to-blue-600">
                      <div class="mx-auto py-16 sm:px-6 lg:px-8">
                        <div class="relative isolate overflow-hidden px-6 py-24 text-center sm:rounded-3xl sm:px-16">
                          <h2 class="font-nudge-extrabold  mx-auto max-w-2xl text-3xl font-bold uppercase tracking-wide sm:text-4xl">
                            REJOIGNEZ NOTRE COMMUNAUTÉ D'INGENIEURS DES SYSTEMES
                            D'INFORMATION & DE DEVELOPPEURS
                          </h2>
                          <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-100">
                            Découvrez les avantages de notre communauté. Il n'y
                            a pas d'obligation, il suffit de soumettre son CV,
                            Intégrer le groupe télégramme et d'explorer les
                            opportunités.
                          </p>
                          <div class="isolate mt-8 flex items-center justify-center -space-x-2 overflow-hidden">
                            <img
                              class="relative z-30 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                              src=".././d_d.webp"
                              alt=""
                            />
                            <img
                              class="relative z-20 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                              src=".././dd.webp"
                              alt=""
                            />
                            <img
                              class="relative z-10 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                              src=".././boy.webp"
                              alt=""
                            />
                            <img
                              class="relative z-0 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                              src=".././dev.jpg"
                              alt=""
                            />
                            <span class="!ml-2 font-bold italic text-gray-800">
                              Envoyez-nous votre CV à l'adresse :{" "}
                              <span class="text-yellow-800 dark:text-violet-500">
                                contact@stic-congo.com
                              </span>{" "}
                            </span>
                          </div>
                          <div class="mt-12 flex items-center justify-center gap-x-6">
                            <a
                              href="https://t.me/+SCZdV8yatNE2NTc0"
                              target="_blank"
                              class="text-md relative inline-flex items-center gap-x-2 rounded-lg bg-red-600 px-6 py-4 font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                            >
                              <span class="absolute -top-5 left-0 w-full text-left text-xs italic text-gray-800">
                                Demarrer maintenant
                              </span>
                              Intégrer le groupe
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                aria-hidden="true"
                                class="-mr-0.5 h-5 w-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    */
                    {/* GROUPE 1 */}
                  </div>

                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </nav>
    </header>
  );
}
export default Header;
