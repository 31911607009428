import { useState, useEffect } from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import { Fragment } from "react";
import ScrollButton from "./ScrollButton";
import { Content, Heading } from "./Styles"; 


function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <section class="py-10 bg-gradient-to-r from-blue-800 to-red-500 sm:pt-16 lg:pt-24">
      <div></div>
      <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
          <div class="col-span-2  md:col-span-3 lg:col-span-2 lg:pr-8">
            <p className="text-3xl flex bg-gradient-to-r from-blue-500 to-yellow-500 text-3xl font-bold bg-clip-text text-transparent hover:text-white">
              {" "}
              {/* <img class="w-auto h-9" src=".././Logo-St.png" alt="" /> */}
              <img
                class="w-auto h-9"
                src="https://www.stic-congo.com/Logo-St.png"
                alt=""
              />
              STIC CONGO{" "}
            </p>
            <p class="text-base text-white text-justify leading-relaxed text-gray-600 mt-7">
              Notre expérience et expertise dans le numérique est une arme pour
              vous dans le développement de votre business
            </p>
          </div>

          <div>
            <p class="text-sm font-bold tracking-widest text-yellow-500 uppercase">
              STIC CONGO
            </p>

            <ul class="mt-6 space-y-4">
              <li>
                <a
                  href="#apropos"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-white w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>{" "}
                  A propos{" "}
                </a>
              </li>

              <li>
                <a
                  href="#services"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-white w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>{" "}
                  Nos services{" "}
                </a>
              </li>

              <li>
                <a
                  href="#expertises"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-white w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>{" "}
                  Nos expertises{" "}
                </a>
              </li>

              <li>
                <a
                  href="/golive"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-white w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>{" "}
                  GoLive{" "}
                </a>
              </li>
            </ul>
          </div>

          <div>
            <p class="text-sm font-bold tracking-widest text-yellow-500 uppercase">
              LIENS UTILES
            </p>

            <ul class="mt-6 space-y-4">
              <li>
                <a
                  href="#contact"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-white w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>{" "}
                  Nous contacter{" "}
                </a>
              </li>

              <li>
                <button
                  onClick={() => setShowModal(true)}
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-white w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>{" "}
                  Partenariat{" "}
                </button>
              </li>

              <li>
                <a
                  href="./conditionsgeneralesventes"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    class="text-white w-6 h-6 flex-shrink-0 mr-4"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>{" "}
                  CGV{" "}
                </a>
              </li>

              {/* <li>
                <a
                  href="#"
                  title=""
                  class="flex text-base text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  Mention Légale{" "}
                </a>
              </li> */}
            </ul>
          </div>

          <div class="col-span-2 md:col-span-1 lg:col-span-2 lg:pl-8">
            <p class="text-sm font-bold tracking-widest text-yellow-500 uppercase">
              Suivez-nous
            </p>

            <ul class="flex items-center space-x-3 mt-9">
              <li>
                <a
                  href="https://twitter.com/congostic"
                  title="twitter"
                  target="_blank"
                  class="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                >
                  <svg
                    class="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.facebook.com/STICONGO"
                  target="_blank"
                  title="facebook"
                  class="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                >
                  <svg
                    class="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                  </svg>
                </a>
              </li>
              {/* Istagram */}
              <li>
                <a
                  href="https://www.instagram.com/sticcongo242/"
                  title="instagram"
                  class="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                >
                  <svg
                    class="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                    <circle cx="16.806" cy="7.207" r="1.078"></circle>
                    <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/stic-congo/?viewAsMember=true"
                  title="instagram"
                  class="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M9.429 8.969h3.714v1.85c.535-1.064 1.907-2.02 3.968-2.02c3.951 0 4.889 2.118 4.889 6.004V22h-4v-6.312c0-2.213-.535-3.461-1.897-3.461c-1.889 0-2.674 1.345-2.674 3.46V22h-4zM2.57 21.83h4V8.799h-4zM7.143 4.55a2.53 2.53 0 0 1-.753 1.802a2.573 2.573 0 0 1-1.82.748a2.59 2.59 0 0 1-1.818-.747A2.548 2.548 0 0 1 2 4.55c0-.677.27-1.325.753-1.803A2.583 2.583 0 0 1 4.571 2c.682 0 1.336.269 1.819.747c.482.478.753 1.126.753 1.803"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
            <a
              class="w-full min-w-xl"
              href="https://www.youtube.com/@sticcongo7035"
              target="_blank"
            >
              <img src=".././youtube.svg" alt="Youtube Button" class="h-28" />
            </a>
          </div>
          <div className="floating-whatsapp">
            <FloatingWhatsApp
              phoneNumber="243834621364"
              accountName="Marie-Louise"
              avatar=".././young-african.jpeg"
              statusMessage="En ligne"
              chatMessage="Bonjour je suis Marie-Louise 👋 !  Je suis là pour vous aider. Tapez votre message et démarrons la discussion en privée ! "
              placeholder="Tapez votre message ici.."
              allowEsc
              allowClickAway
              notification
              notificationSoundSrc=".././whatsapp_notification.mp3"
              notificationSound={true}
              notificationDelay={5}
              messageDelay={2}
              true
            />
          </div>
        </div>

        <hr class="mt-16 mb-10 border-gray-200" />

        <p class="text-sm text-center text-gray-100">
          © Copyright 2023 STIC CONGO
        </p>
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto w-full">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl text-gray-700 mx-auto ">
                      {/* TEST DE MESSAGE */}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}

                  <div
                    id="works"
                    class="relative bg-blue-800 py-10 sm:py-16 lg:py-24"
                  >
                    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                      <div class="max-w-2xl mx-auto text-center">
                        <h2 class="text-4xl text-white font-extrabold mx-auto md:text-6xl lg:text-5xl">
                          Travaillons ensemble !
                        </h2>
                        <p class="max-w-2xl mx-auto mt-4 text-base text-gray-400 leading-relaxed md:text-2xl">
                          Gagnez de l'argent avec nos solutions entreprise et
                          individuelle
                        </p>
                      </div>
                      <div class="relative mt-12 lg:mt-20">
                        <div class="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                          <img
                            alt=""
                            loading="lazy"
                            width="1000"
                            height="500"
                            decoding="async"
                            data-nimg="1"
                            class="w-full"
                            src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg"
                          />
                        </div>
                        <div class="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                          <div>
                            <div class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                              <span class="text-xl font-semibold text-gray-700">
                                01
                              </span>
                            </div>
                            <h3 class="mt-6 text-xl  text-white font-semibold leading-tight md:mt-10">
                              Apporteur d'affaires
                            </h3>
                            <p class="mt-4 text-base text-gray-400 md:text-lg">
                              Gagnez des commissions en proposant nos produits
                              et services en entreprise
                            </p>
                          </div>
                          <div>
                            <div class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                              <span class="text-xl font-semibold text-gray-700">
                                02
                              </span>
                            </div>
                            <h3 class="mt-6 text-xl text-white font-semibold leading-tight md:mt-10">
                              Revendeur du détail
                            </h3>
                            <p class="mt-4 text-base text-gray-400 md:text-lg">
                              Prenez les commandes et nous vous livrons à bonne
                              date avec votre branding
                            </p>
                          </div>
                          <div>
                            <div class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                              <span class="text-xl font-semibold text-gray-700">
                                03
                              </span>
                            </div>
                            <h3 class="mt-6 text-xl text-white font-semibold leading-tight md:mt-10">
                              Devenir actionnaire à STIC CONGO
                            </h3>
                            <p class="mt-4 text-base text-gray-400 md:text-lg">
                              Nous ouvrons nos actions pour un complexe
                              multinational. Contactez-nous !
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="absolute inset-0 m-auto max-w-xs h-[357px] blur-[118px] sm:max-w-md md:max-w-lg"></div>
                  </div>

                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>

      <Fragment>
        <Content />
        <ScrollButton />
      </Fragment>
    </section>
  );
  
}
export default Footer;
